<template>
    <v-layout wrap>
        <v-flex
            class="d-flex justify-space-between flex-column flex-md-row"
            xs12
        >
            <span class="display-1 px-5 pb-5 font-weight-medium">{{
                registry.name
            }}</span>
            <v-btn
                @click="add"
                class="mb-2 mb-md-0"
                color="secondary"
                v-if="$auth.check() && registry.category && hasPermission"
                >Adaugă intrare în registru</v-btn
            >
        </v-flex>
        <v-flex ml-0 ml-lg-5 xs12>
            <v-card>
                <v-card-title>
                    <v-menu
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                        v-model="monthMenu"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                @click:append="clearMonth"
                                append-icon="mdi-close"
                                hide-details
                                label="Alegeți luna"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-model="month"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker
                            @change="formatMonth(filterQuery.month)"
                            color="secondary"
                            no-title
                            reactive
                            scrollable
                            type="month"
                            v-model="filterQuery.month"
                        />
                    </v-menu>

                    <v-spacer />
                    <v-select
                        :items="years"
                        @change="applyYear"
                        clearable
                        hide-details
                        label="Alegeți anul"
                        v-model="filterQuery.year"
                    />
                    <v-spacer />
                    <v-text-field
                        @keydown.enter="getData"
                        append-icon="mdi-magnify"
                        hide-details
                        label="Caută Înregistrare"
                        single-line
                        v-model="searchQuery"
                    />
                </v-card-title>
                <v-data-table
                    :footer-props="{
                        'items-per-page-options': $vuetifyTableItemsPerPage
                    }"
                    :headers="headers"
                    :items="data.items"
                    :loading="loading"
                    :options.sync="data.pagination"
                    :server-items-length="data.total"
                    @update:options="getData"
                >
                    <template v-slot:item.date="{ item }">
                        <div class="font-weight-medium text-no-wrap">
                            {{ item.number }} /
                            {{ item.year }}
                        </div>
                    </template>
                    <template v-slot:item.info="{ item }">
                        <v-layout column>
                            <v-flex
                                pt-2
                                v-show="item.meta && parse(item.meta).tag"
                            >
                                <v-chip color="primary" label x-small>{{
                                    parse(item.meta).tag
                                }}</v-chip>
                            </v-flex>
                            <v-flex class="title" py-2>
                                {{ item.name }}
                            </v-flex>
                            <v-flex pb-2>
                                <v-btn @click="open(item)" x-small>
                                    <v-icon x-small>mdi-file</v-icon>
                                    Documentație Proiect
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </template>
                    <template v-slot:item.state="{ item }">
                        {{ formatDate(item.display_date) }}
                    </template>
                    <template v-slot:item.directed="{ item }">
                        <div class="text-no-wrap">
                            {{ parse(item.meta).directed }}
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex flex-column flex-md-row justify-end">
                            <v-btn
                                @click="show(item)"
                                color="primary"
                                small
                                class="my-1 my-md-0"
                                v-if="isHotarare"
                                >Vizualizează</v-btn
                            >
                            <v-btn
                                :disabled="!item.src"
                                :href="item.src"
                                target="_blank"
                                color="primary"
                                small
                                v-else
                                class="my-1 my-md-0"
                                >Vizualizează</v-btn
                            >
                            <v-btn
                                v-if="$auth.check() && hasPermission"
                                class="ml-0 ml-md-2 my-1 my-md-0"
                                @click="edit(item)"
                                color="success"
                                small
                                >Editează</v-btn
                            >
                            <v-btn
                                v-if="$auth.check() && hasPermission"
                                class="ml-0 ml-md-2 my-1 my-md-0"
                                @click="destroy(item)"
                                color="error"
                                small
                                >Șterge</v-btn
                            >
                        </div>
                    </template>
                </v-data-table>
            </v-card>
            <v-dialog fullscreen v-model="dialog">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <v-btn @click="close" icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-title primary-title>
                        <p class="headline">{{ record.name }}</p>
                        <v-spacer />
                    </v-card-title>
                    <v-card-text
                        class="ql-editor"
                        v-html="record.documentation"
                    />
                    <v-card-text>
                        <p class="headline">Atașamentele Documentației</p>
                        <a
                            :href="doc.url"
                            :key="index"
                            style="text-decoration: none"
                            target="_blank"
                            v-for="(doc, index) in record.docs"
                        >
                            <v-card class="my-2" hover>
                                <v-card-text class="subtitle-2">{{
                                    doc.name
                                }}</v-card-text>
                            </v-card>
                        </a>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import FormatDate from "@/mixins/formatDate.js";
import hasPermission from "@/mixins/hasPermission";

export default {
    name: "Registry",
    metaInfo: {
        title: "Registru -"
    },
    mixins: [FormatDate, hasPermission],
    data: () => ({
        dialog: false,
        registry: {},
        data: {},
        monthMenu: false,
        month: "",
        years: [],
        record: {},
        loading: false,
        slug: "",
        searchQuery: "",
        filterQuery: {},
        headers: [
            { text: "Nr./Dată", align: "left", sortable: false, value: "date" },
            {
                text: "Informații Înregistrare",
                align: "left",
                sortable: false,
                value: "info"
            },
            {
                text: "Data Înregistrării",
                align: "left",
                sortable: false,
                value: "state"
            },
            { text: "Acțiuni", align: "end", sortable: false, value: "actions" }
        ]
    }),
    computed: {
        isHotarare() {
            return parseInt(this.registry.id) === 5;
        }
    },
    watch: {
        "$route.query.year": {
            handler(val) {
                if (val) {
                    this.filterQuery.year = val;
                }
            },
            immediate: true
        },
        dialog(value) {
            return value || this.close();
        },
        isHotarare: {
            handler: function(val) {
                if (val) {
                    this.headers.splice(3, 0, {
                        text: "Cui a fost repartizat",
                        align: "left",
                        sortable: false,
                        value: "directed"
                    });
                }
            },
            immediate: true
        }
    },
    created() {
        this.generateArrayOfYears();
    },
    methods: {
        edit(item) {
            this.$router.push({
                name: "edit-record",
                query: {
                    id: item.slug,
                    "id-registru": this.registry.id
                }
            });
        },
        generateArrayOfYears() {
            const max = new Date().getFullYear();
            const min = max - 10;

            for (let i = max; i >= min; i--) {
                this.years.push(i);
            }
        },
        async destroy(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că vreți să ștergeți înregistrarea?"
            });
            if (res) {
                try {
                    await this.axios.post(`/records/${item.slug}`, {
                        _method: "DELETE"
                    });
                    const index = this.data.items.indexOf(item);
                    this.data.items.splice(index, 1);
                } catch (e) {
                    console.log(e);
                }
            }
        },

        applyYear() {
            localStorage.setItem("year", this.filterQuery.year);
            this.getData();
        },
        clearMonth() {
            this.filterQuery.month = "";
            this.month = "";
            this.getData();
        },
        formatMonth(rawMonth) {
            const monthNumber = rawMonth.slice(-2);
            switch (monthNumber) {
                case "01":
                    this.month = "Ianuarie";
                    break;
                case "02":
                    this.month = "Februarie";
                    break;
                case "03":
                    this.month = "Martie";
                    break;
                case "04":
                    this.month = "Aprilie";
                    break;
                case "05":
                    this.month = "Mai";
                    break;
                case "06":
                    this.month = "Iunie";
                    break;
                case "07":
                    this.month = "Iulie";
                    break;
                case "08":
                    this.month = "August";
                    break;
                case "09":
                    this.month = "Septembrie";
                    break;
                case "10":
                    this.month = "Octombrie";
                    break;
                case "11":
                    this.month = "Noiembrie";
                    break;
                case "12":
                    this.month = "Decembrie";
                    break;
            }
            this.getData();
        },
        close() {
            this.item = {};
            this.dialog = false;
        },
        open(item) {
            this.record = item;
            this.dialog = true;
        },
        show(item) {
            if (this.registry.id === 5) {
                this.$router.push({
                    name: "view-record",
                    query: {
                        slug: item.slug
                    }
                });
            }
        },
        add() {
            this.$router.push({
                name: "add-record",
                query: {
                    slug: this.slug,
                    "id-registru": this.registry.id
                }
            });
        },
        parse(meta) {
            return JSON.parse(meta);
        },
        async getData() {
            this.loading = true;
            const urlParams = new URLSearchParams(location.search);
            this.slug = urlParams.get("slug");
            let response = await this.axios.get(`/registries/${this.slug}`);
            this.registry = response.data[0];
            response = await this.axios.get(`/records/${this.registry.id}`, {
                params: {
                    ...this.data.pagination,
                    searchQuery: this.searchQuery,
                    filterQuery: {
                        ...this.filterQuery
                    }
                }
            });
            this.data = response.data;
            this.loading = false;
        }
    }
};
</script>

<style scoped></style>
